'use strict';

import { NUMERIC_REGEXP } from './constants/regexp.js';

const otherInput = document.getElementById('donate-amount');
const amountButtons = document.querySelectorAll('.donate__amount-button');

//* Replacing the input text by clicking *//
otherInput.addEventListener('focus', () => {
  otherInput.placeholder = 'enter any amount';
});

otherInput.addEventListener('focusout', () => {
  otherInput.placeholder = 'other';
});

//* Adding transparency to buttons with fixed amounts when clicking on an input *//
otherInput.addEventListener('click', () => {
  amountButtons.forEach((button) => button.classList.add('opacity'));
});

otherInput.addEventListener('focusout', () => {
  amountButtons.forEach((button) => button.classList.remove('opacity'));
});

//* Validation of the DONATE form input *//
const inputElement = document.getElementById('donate-amount');

inputElement.addEventListener('input', (event) => {
  const inputValue = event.target.value;

  const numericValue = inputValue.replace(NUMERIC_REGEXP, ''); // remove non-numeric characters other than the dot
  const isValid = inputValue === numericValue; // check whether the entered value is correct

  // If the value is incorrect, update the input field
  if (!isValid) {
    event.target.value = numericValue;
  }

  // Check that the point is entered only once
  const decimalPointCount = inputValue.split('.').length - 1;
  if (decimalPointCount > 1) {
    event.target.value = inputValue.substring(
      0,
      inputValue.lastIndexOf('.') + 1,
    );
  }
});

inputElement.addEventListener('keydown', (event) => {
  if (event.key === '+' || event.key === '-') {
    event.preventDefault(); // prevent the input of + or characters -
  }
});

inputElement.addEventListener('mousewheel', (event) => {
  event.preventDefault(); // prevent the value from changing when scrolling with the mouse wheel
});
